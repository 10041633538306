<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card class="primary--border" outlined>
          <v-card-title class="title">
            Behavior Review and Remarks
            <v-spacer />
            <add-button :permission="true" @action="
              (form.dialog = true), $refs.form ? $refs.form.reset() : ''
              ">
              Add New
            </add-button>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="pb-0 mb-0">
              <v-flex mb-0 xs6>
                <!-- <v-grade-field v-model="gradeId" :multiple="true"></v-grade-field> -->
                <v-select :items="grades" v-model="gradeId" label="Select Grades" multiple class="pt-0" outlined dense>
                  <v-list-item slot="prepend-item" ripple @click="toggle(0)">
                    <v-list-item-action>
                      <v-icon :color="gradeId.length > 0 ? 'indigo darken-4' : ''">
                        {{ icon1 }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-title>Select All</v-list-item-title>
                  </v-list-item>
                  <v-divider slot="prepend-item" class="mt-2"></v-divider>
                  <v-divider slot="append-item" class="mb-2"></v-divider>
                </v-select>
              </v-flex>
              <v-flex mb-4 xs4>
                <v-select :items="groups" required class="pa-0 mb-0" label="Group Type" outlined dense
                  v-model="group_status">

                </v-select>

              </v-flex>
              <v-spacer></v-spacer>
              <search-button style="margin-bottom: 10px" :permission="true" @action="get()">
                Search
              </search-button>
            </v-card-title>
          </v-card>
          <v-data-table :headers="headers" :items="form.items.data" :options.sync="pagination" :footer-props="footerProps"
            :server-items-length="form.items.meta.total" :loading="form.loading">
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">
                  {{ item.title }}
                  <v-chip small label outlined
                    :color="item.group_type === 'positive' ? 'success' : 'error'">{{ item.group_type }}</v-chip>

                  <div v-if="item.description">
                    <span>
                      <view-more-or-less :paragraph="item.description" limit="70"></view-more-or-less>
                    </span>
                  </div>
                </td>
                <td>
                  <template v-for="gr in item.gradeRemark">
                    <v-chip :key="gr.id" small label color="primary" style="margin: 2px">
                      {{ gr.name }}
                    </v-chip>
                    <!-- <v-chip
                      :key="gr.id"
                      style="cursor: pointer"
                      small
                      label
                      class="mr-1 mb-1 mt-1"
                    >
                      {{ gr.name }}
                    </v-chip> -->
                  </template>
                </td>
                <td class="text-right">
                  <edit-button :permission="true" @agree="form.edit(item), setData(item.gradeRemark)" />
                  <delete-button :permission="true" @agree="form.delete(item.id)" />
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-dialog @keydown.esc="form.reset(), (form.dialog = false)" v-model="form.dialog" persistent max-width="400px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add/Update</span>
          </v-card-title>
          <v-card-text class="pb-1 pt-4">
            <v-form ref="form" v-model="valid" :lazy-validation="lazy" @submit.prevent="save"
              @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="validate">
              <v-text-field autocomplete="off" label="Title *" required class="pa-0" outlined dense v-model="form.title"
                name="title" :error-messages="form.errors.get('title')" :rules="[(v) => !!v || 'Title is required']" />
              <v-select :items="groupType" class="pa-0" label="Behaviour Group" v-model="form.group_type" outlined dense
                name="behaviour_group" :error-messages="form.errors.get('behaviour_group')"
                :rules="[(v) => !!v || 'Behaviour Group is required']">
              </v-select>
              <v-select :items="grades" v-model="form.grade_id" label="Select Grades" multiple class="pt-0" outlined
                dense>
                <v-list-item slot="prepend-item" ripple @click="toggle(1)">
                  <v-list-item-action>
                    <v-icon :color="form.grade_id.length > 0 ? 'indigo darken-4' : ''">
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-title>Select All</v-list-item-title>
                </v-list-item>
                <v-divider slot="prepend-item" class="mt-2"></v-divider>
                <v-divider slot="append-item" class="mb-2"></v-divider>
              </v-select>

              <v-textarea outlined name="description" label="Description (optional)"
                v-model="form.description"></v-textarea>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text @click="(form.dialog = false), form.reset()">Close</v-btn>
            <v-btn color="success" text @click="validate"
              :disabled="form.grade_id.length == 0 ? true : false">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";
import { validate } from "json-schema";

export default {
  mixins: [Mixins],
  data: () => ({
    valid: true,
    lazy: false,
    grades: [],
    gradeId: "",
    form: new Form(
      {
        dialog: false,
        grade_id: [],
        title: "",
        description: "",
        group_type: '',
      },
      "/api/remark-labels"
    ),
    headers: [
      { text: "#", align: "left", value: "id", sortable: false },
      { text: "Title", align: "left", value: "title", sortable: false },
      { text: "Grade", align: "left", value: "grades", sortable: false },
      { text: "Action", align: "right", sortable: false },
    ],
    groups: [

      { value: 'all', text: "All" },
      { value: 'positive', text: "Positive" },
      { value: 'negative', text: "Negative" },
    ],
    group_status: 'all',
    // groupTypes:[
    //   {value:'positive', text:"Positive"},
    //   {value:'positive', text:"Positive"},
    //   {value:'negative', text:"Negative"},
    // ]
  }),
  computed: {
    groupType() {
      return this.groups.filter((item) => {
        if (item.value !== 'all') {
          return item;
        }
      })
    },
    selectAllGrades() {
      return this.form.grade_id.length === this.grades.length;
    },
    selectFewGrades() {
      return this.form.grade_id.length > 0 && !this.selectAllGrades;
    },
    icon() {
      if (this.selectAllGrades) return "check_box";
      if (this.selectFewGrades) return "indeterminate_check_box";
      return "add_box";
    },
    selectAllGrades1() {
      return this.gradeId.length === this.grades.length;
    },
    selectFewGrades1() {
      return this.gradeId.length > 0 && !this.selectAllGrades;
    },
    icon1() {
      if (this.selectAllGrades1) return "check_box";
      if (this.selectFewGrades1) return "indeterminate_check_box";
      return "add_box";
    }
  },
  mounted() {
    this.getGrades();
  },
  watch: {
    pagination: function () {
      this.get();
    },
  },
  methods: {
    setData(item) {
      let $this = this;
      item.forEach(function (value) {
        $this.form.grade_id.push(value.id);
      });
    },
    get(params) {
      let extraparams = "&grade_id=" + this.gradeId + "&group_type=" + this.group_status;

      let query = [null, undefined].includes(params)
        ? this.queryString(extraparams)
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.form.store();
      }
    },
    getGrades() {
      this.$rest
        .get(
          "/api/grades?rowsPerPage=25&sortBy=rank&descending=false&roleWise=true&slim=true"
        )
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        });
    },

    toggle(status) {
      this.$nextTick(() => {
        if (status === 1) {
          if (this.selectAllGrades) {
            this.form.grade_id = [];
          } else {
            this.form.grade_id = this.grades.map((grade) => {
              return grade.value;
            });
          }
        } else {
          if (this.selectAllGrades1) {
            this.gradeId = [];
          } else {
            this.gradeId = this.grades.map((grade) => {
              return grade.value;
            });
          }
        }
      });
    },
  },
};
</script>
